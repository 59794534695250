<template>
	<div class='displayFlex-1 flexColumn OverflowAuto'>
		<!-- user -->
		<User :headerDate="headerDate"></User>
		<!-- product_recommendations_option.length === 0 -->
		<template v-if="basis_recommendations.product_recommendations_option.length === 0">
			<!-- 添加proposal -->
			<div class="bigCircle" @click="addProposal">
				<div class="innerCircle">
					<div class="circle">
						<i class="el-icon-plus"></i>
					</div>
				</div>
			</div>
		</template>
		<template v-if="basis_recommendations.product_recommendations_option.length !== 0">
			<!-- proposal  tab页标题 -->
			<el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true class="my-tab">
				<el-tab-pane v-for="(item, index) in editableTabs" :key="index" :label="item.title" :name="item.title">
				</el-tab-pane>
			</el-tabs>
			<!-- 各tab页对应的详情页面 basis_recommendations.product_recommendations_option-->
			<div class='displayFlex flexColumn flexJCCenter'>
				<!--  protection needs setup -->
				<template v-if="dataValue.accredited_investor_declaration.is_ai !== 'Y'">
					<div class="mb20px w90" style="overflow-x: scroll;white-space: nowrap;">
						<template v-for="(item, index) in show_needs">
							<div v-if="item.is_select_type === 'Y'" :key="index" style="padding: 10px;display: inline-block;">
								<div class="p10px smallBox" :style="{ background: getColor(item.protection_needs)}">
									{{item.protection_name}}
								</div>
							</div>
						</template>
					</div>
					<i class="el-icon-right pointer iStyle mlAuto mb20px mr40px" style="margin-top: -60px;" @click="$router.push('/NeedsView?proposal=' + currentTab)"></i>
				</template>

				<!-- 列表 -->
				<div class="listStyle p20px">
					<div v-for="(item, index) in basis_recommendations.product_recommendations_option[currentTab].product_list" :key="index">
						<div class="displayFlex flexAlignCenter productItem" v-if="item.is_recommendation === 'Y'">
							<!-- 序号 -->
							<div class="w30px h30px orderStyle TextAlginCenter">{{index + 1}}</div>
							<!-- 名称 -->
							<p class="productNameStyle w50 ml20px">{{item.product_name}}</p>
							<!-- 金额 -->
							<p class="productDescStyle" v-if="item.product_line_code === 'CI'">Lump Sum $ {{$formatMoney(item.benefit_amount)}} for {{getNameByCode(item.product_for)}} and $ {{$formatMoney(item.premium_amount)}} RIS({{item.premium_mode}})</p>
							<p class="productDescStyle" v-else>$ {{$formatMoney(item.premium_amount)}} {{item.premium_mode}} for SA $ {{$formatMoney(item.benefit_amount)}} for {{getNameByCode(item.product_for)}}</p>
							<!-- 颜色 -->
							<span v-for="need in item.need" :key="need">
								<div class="circle_small" :style="{ background: getColor(need)}"></div>
							</span>
							<i class="el-icon-right pointer iStyle mlAuto" @click="$router.push('/MainPlanView?proposal=' + currentTab + '&product=' + index)"></i>
						</div>
						<div v-for="(riderItem, riderindex) in item.rider_list" :key="riderindex">
							<div class="displayFlex flexAlignCenter productItem ml10px" v-if="riderItem.is_recommendation === 'Y'">
								<!-- 名称 -->
								<p class="productNameStyle ml20px" style="width: 52%;">{{riderItem.product_name}}</p>
								<!-- 金额 -->
								<p class="productDescStyle">$ {{$formatMoney(riderItem.premium_amount)}} {{riderItem.premium_mode}} for $ {{$formatMoney(riderItem.benefit_amount)}}</p>
								<!-- 颜色 -->
								<span v-for="need in riderItem.need" :key="need">
									<div class="circle_small" :style="{ background: getColor(need)}"></div>
								</span>
								<i class="el-icon-right pointer iStyle mlAuto" @click="$router.push('/RiderView?proposal=' + currentTab + '&product=' + index + '&rider=' + riderindex)"></i>
							</div>
						</div>
						<div v-for="(fundItem, fundindex) in item.fund_list" :key="fundindex">
							<div class="displayFlex flexAlignCenter productItem ml10px" v-if="fundItem.is_recommendation === 'Y'">
								<!-- 名称 -->
								<p class="productNameStyle ml20px" style="width: 52%;">{{fundItem.fund_name}}</p>
								<!-- 金额 -->
								<p class="productDescStyle" v-if="item.product_line_code === 'CI'">
									S$ {{$formatMoney(fundItem.amount)}} for Lump Sum & S$ {{$formatMoney(fundItem.type_account)}} for RIS({{item.premium_mode}})
								</p>
								<p v-else class="productDescStyle">{{$formatMoney(fundItem.percentage_allocation)}} % of premium allocation</p>
								<!-- 颜色 -->
								<span v-for="need in fundItem.need" :key="need">
									<div class="circle_small" :style="{ background: getColor(need)}"></div>
								</span>
								<i class="el-icon-right pointer iStyle mlAuto" @click="$router.push('/FundView?proposal=' + currentTab + '&product=' + index + '&fund=' + fundindex)"></i>
							</div>
						</div>
					</div>
				</div>
				<!-- Basis of recommendation -->
				<div class='displayFlex flexAlignCenter h60px' style="padding: 0px 40px 0 20px;">
					<MyTitle Title="Basis of recommendation"/>
					<i @click="$router.push('/BasisOfRecommendation?proposal=' + currentTab + '&Annualised=' + overBudgeAnnualised + '&Single=' + overBudgeSingle)" class="el-icon-right pointer iStyle mlAuto"></i>
				</div>
			</div>

			<!-- add、delete    proposal-->
			<div class="displayFlex flexJCCenter mt30px">
				<el-button type="warning" size="medium" @click="addProposal">Add Proposal</el-button>
				<el-button type="danger" size="medium" @click="delProposal">Delete Proposal</el-button>
			</div>

			<!-- 添加产品按钮 -->
			<div class="circle circle-single">
				<i class="el-icon-plus" @click="openDialog"></i>
			</div>

			<!-- Total Annualised Premium、Total Single Premium -->
			<div class="displayFlex" style="bottom: 10px;position: absolute;">
				<div class="displayFlex flexColumn flexJCCenter flexAlignCenter ml20px" :style="overBudgeAnnualised ? {color: 'red'} : ''">
					<p style="font-size: 28px;margin-bottom: 10px;">{{total_annualised_premium_show}}</p>
					<p>Total Annualised Premium</p>
				</div>
				<div class="displayFlex flexColumn flexJCCenter flexAlignCenter ml20px" :style="overBudgeSingle ? {color: 'red'} : ''">
					<p style="font-size: 28px;margin-bottom: 10px;">{{total_single_premium_show}}</p>
					<p>Total Single Premium</p>
				</div>
			</div>
		</template>
		<ChooseProduct ref="chooseProduct" @add_product_list="add_product_list"></ChooseProduct>
		<ElementDialog Title="Important notice" :Visible.sync="Dialog.visible" :showClose="false" class="my-dialog-title">
			<div slot="content">
				<p style="font-size: 16px;">If use CPF or SRS fund to buy the product,please check the premium within your respective CPF account or SRS account budget</p>
				<!-- 表格 Dependant's Details -->
				<el-table :data="assetsTableData" :cell-style="cellStyle" style="width: 100%">
					<el-table-column prop="name" label="Source of funds">
					</el-table-column>
					<el-table-column prop="Annual" label="Annual Amount" width="150">
						<template slot-scope="scope">
							<span>{{scope.row.AnnualShow}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="Single" label="Lump Sum" width="150">
						<template slot-scope="scope">
							<span>{{scope.row.SingleShow}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="BtnSolt" class="TextAlginRight">
				<el-button @click="Dialog.visible = false">Cancel</el-button>
				<el-button type="warning" @click="productSubmit">Submit</el-button>
			</div>
		</ElementDialog>
	</div>
</template>

<script>
import MyTitle from "../components/MyTitle.vue";
import User from "./components/User.vue";
import ChooseProduct from "./detail/ChooseProduct.vue";
import { getSystemPrompts } from "@/utils/kyc"
import ElementDialog from "@/components/ElementDialog/index"
import { setAllKYC } from "@/utils/kyc"

export default {
	components: { MyTitle, User, ChooseProduct, ElementDialog },
	data () {
		return {
			// user传值
			headerDate: {},
			// tab
			activeName: 'Proposal1',
			editableTabs: [],
			// 对于有重复的needs时，只展示第一个
			show_needs: [],
			currentTab: 0,
			basis_recommendations: {
				total_single_premium: '',
				plan_risk_limitation: '',
				recommendation_reason: '',
				total_assets_under_advice: '',
				deviation_reason: '',
				is_deviation: '',
				product_recommendations_option: [],
				fees_charges: '',
				total_annualised_premium: '',
				additional_notice: ''
			},
			total_single_premium_show: '',
			total_annualised_premium_show: '',
			overBudgeAnnualised: false,
			overBudgeSingle: false,
			// 从1.12节点获取needs
			protectionNeeds: [],
			kycAllData: {},
			dataValue: {},
			Dialog: {
				visible: false,
				status: "Add",
			},
			assetsTableData: [
				{
					name: 'Cash',
					Annual: '0',
					Annual_value: 'cash_annual',
					Single: '0',
					Single_value: 'cash_single'
				}, {
					name: 'CPF Ordinary Account',
					Annual: '0',
					Annual_value: 'cpf_ordinary_account_annual',
					Single: '0',
					Single_value: 'cpf_ordinary_account_single'
				}, {
					name: 'CPF Special Account',
					Annual: '0',
					Annual_value: 'cpf_special_account_annual',
					Single: '0',
					Single_value: 'cpf_special_account_single'
				}, {
					name: 'CPF Medisave Account',
					Annual: '0',
					Annual_value: 'cpf_medisave_account_annual',
					Single: '0',
					Single_value: 'cpf_medisave_account_single'
				}, {
					name: 'Supplementary Retirement Scheme (SRS)',
					Annual: '0',
					Annual_value: 'retirement_account_annual',
					Single: '0',
					Single_value: 'retirement_account_single'
				}, {
					name: 'Total',
					Annual: '0',
					Annual_value: 'total_annual',
					Single: '0',
					Single_value: 'total_single'
				},
			],
			// 所有needs的数字集合
			needsNUM: [],
			needsFor: []
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		'basis_recommendations.product_recommendations_option' (val) {
			this.$store.commit('SET_BOTTOMBTN', val.length !== 0)
		},
		activeName (val) {
			this.currentTab = parseInt(val.substr(-1)) - 1
			this.$store.commit('SET_ACTIVENAME', val)
		},
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.getDataFromAll(newVal)
			})
		}
	},
	methods: {
		submit_Form () {
			// some退出循环：return / return true（不能return false） every退出循环：return false
			// 1\判断是否都符合要求 {proposal: 0,product: 1}
			let result = null
			this.basis_recommendations.product_recommendations_option.every((proposal, i) => {
				proposal.product_list.every((product, j) => {
					let a = getSystemPrompts({ proposal: i, product: j }, this.$store, this.$confirm)
					result = a
					return a
				})
			})
			if (result) {
				this.Dialog.visible = true
			}
		},
		setKYC () {
			Object.keys(this.dataValue.basis_recommendations).map(key => {
				if (key !== 'additional_notice') this.dataValue.basis_recommendations[key] = this.basis_recommendations[key]
			})
			console.log('2.2节点，kycAllData赋值');
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		getDataFromAll (val) {
			this.dataValue = this.kycAllData.kyc.client[val]
			// user值
			this.headerDate = {
				gender: this.dataValue.personal_information.gender,
				title: this.dataValue.personal_information.title,
				name: this.dataValue.personal_information.full_name,
				cka: this.dataValue.customer_knowledge_assessment.assessment_outcome,
				ai: this.dataValue.accredited_investor_declaration.is_ai,
				grade: this.dataValue.risk_profile_questionnaire.is_agree_profile === 'N' ? this.dataValue.risk_profile_questionnaire.own_choice : this.dataValue.risk_profile_questionnaire.grade
			}
			// 若有值 则赋值
			Object.keys(this.basis_recommendations).map(key => {
				this.basis_recommendations[key] = this.dataValue.basis_recommendations[key]
			})
			this.protectionNeeds = this.$store.state.KYC.protectionNeeds[this.kycClient]
			let total_annualised_premium = 0
			let total_single_premium = 0
			this.editableTabs = []
			this.activeName = 'Proposal1'
			this.$store.commit('SET_ACTIVENAME', this.activeName)
			this.basis_recommendations.product_recommendations_option.map((res, i) => {
				this.editableTabs.push({
					title: 'Proposal' + (i + 1)
				})
				// product_list中needs_analysis_list 与 res.protection_needs_setup 比较
				this.needsNUM = []
				this.show_needs = []
				// 如果点击edit后，直接进入2.2，则取用kycAllData的值
				if (this.protectionNeeds.length === 0) this.protectionNeeds = res.protection_needs_setup
				// 最新的Needs列表,取是否选中的值
				res.protection_needs_setup.map(newNeed => {
					if (newNeed.is_select_type === 'Y') this.needsNUM.push(newNeed.protection_needs)
					if (!this.show_needs.find(n => n.protection_needs === newNeed.protection_needs)) this.show_needs.push(newNeed)
				})
				this.compareArr(this.needsNUM, res.product_list)
				/**
				 * 计算 Total Annualised Premium、Total Single Premium
				 * 累加主产品中的数额，规则如下：
				 * CI Single(Lump Sum Amount) Annualised(RIS M12 Q4 Semi2 Annually Not Aoolication)
				 * LI Single(Premium-Mode=Single) Annualised(Premium M12 Q4 Semi2 Annually)
				 */
				res.product_list.map(main => {
					if (main.is_select_product === 'Y') {
						if (main.product_line_code === 'CI') {
							total_single_premium += Number(main.benefit_amount)
							total_annualised_premium += Number(main.premium_amount) * this.getCount(main.premium_mode)
						} else {
							if (main.premium_mode === 'Single Premium') {
								total_single_premium += Number(main.premium_amount)
							} else {
								total_annualised_premium += Number(main.premium_amount) * this.getCount(main.premium_mode)
							}
						}
					}
					main.rider_list.map(rider => {
						if (rider.is_select_product === 'Y') total_annualised_premium += Number(rider.premium_amount) * this.getCount(rider.premium_mode)
        	})
				})
			})
			this.basis_recommendations.total_annualised_premium = String(total_annualised_premium)
			this.basis_recommendations.total_single_premium = String(total_single_premium)
			this.total_annualised_premium_show = 'S$ ' + this.$formatMoney(total_annualised_premium)
			this.total_single_premium_show = 'S$ ' + this.$formatMoney(total_single_premium)
			// 保存当前数据
			Object.keys(this.dataValue.basis_recommendations).map(key => {
				if (key !== 'additional_notice') this.dataValue.basis_recommendations[key] = this.basis_recommendations[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)

			// 判断是否超过预算，若超过则为红色
			this.overBudgeAnnualised = total_annualised_premium > this.dataValue.budget['total_annual']
			this.overBudgeSingle = total_single_premium > this.dataValue.budget['total_single']

			this.assetsTableData.map((key, i) => {
				key.Annual = this.dataValue.budget[key.Annual_value]
				key.AnnualShow = 'S$ ' + this.$formatMoney((Math.round(key.Annual * 100) / 100))
				key.Single = this.dataValue.budget[key.Single_value]
				key.SingleShow = 'S$ ' + this.$formatMoney((Math.round(key.Single * 100) / 100))
			})

			this.needsFor = [{ code: 'myself', name: 'Myself' }]
			// 根据1.3节点 判断是否有依赖人
			if (this.dataValue.dependants_list.is_dependants === 'Y') {
				this.dataValue.dependants_list.dependants_details.map(res => {
					this.needsFor.push({ code: res.dependant_id, name: res.name })
				})
			}
		},
		getCount (mode) {
			switch (mode) {
				case 'Monthly':
					return 12
				case 'Quarterly':
					return 4
				case 'Semi-Annually':
					return 2
				case 'Annually':
					return 1
				case 'Not Applicable':
					return 1
				case 'Single Premium':
					return 1
			}
		},
		// 添加propasal
		addProposal () {
			let len = this.editableTabs.length + 1
			this.editableTabs.push({
				title: 'Proposal' + len
			})
			this.activeName = 'Proposal' + len
			this.basis_recommendations.product_recommendations_option.push({
				product_list: [],
				protection_needs_setup: JSON.parse(JSON.stringify(this.protectionNeeds))
			})
			this.show_needs = []
			JSON.parse(JSON.stringify(this.protectionNeeds)).map(newNeed => {
				if (!this.show_needs.find(n => n.protection_needs === newNeed.protection_needs)) this.show_needs.push(newNeed)
			})
		},
		// 删除propo
		delProposal () {
			// 删除product——list
			let index = parseInt(this.activeName.substr(-1)) - 1
			this.basis_recommendations.product_recommendations_option.splice(index, 1)
			// 删除tab页
			this.activeName = 'Proposal' + this.currentTab
			this.editableTabs.pop()
		},
		// 添加产品
		add_product_list (obj) {
			this.basis_recommendations.product_recommendations_option[this.currentTab].product_list.push(obj)
			this.compareArr(this.needsNUM, [obj])
		},
		// tab点击事件
		handleClick (tab, event) {
		},
		getColor (needs) {
			return global.needs.find(item => item.code == needs).color
		},
		// 添加产品 
		openDialog () {
			// 如果没有need并且AI未高亮，则无法选择产品
			if (this.show_needs.length === 0 && this.dataValue.accredited_investor_declaration.is_ai !== 'Y') {
				this.$confirm('There is no suitable need', 'Alert', {
					showConfirmButton: false,
					cancelButtonText: 'OK',
					type: "info",
				}).then(() => { }).catch(() => { })
			} else {
				this.$refs.proposalIndex = this.currentTab
				this.$refs.chooseProduct.Dialog.visible = true
			}
		},
		// product中的needs_analysis_list与needs比较，相同的则放在need中，用于展示颜色
		compareArr (needs, productList) {
			productList.map(res => {
				res.need = res.needs_analysis_list.filter(item => needs.some(ele => ele === item))
				res.rider_list.map(rider => {
					rider.need = rider.needs_analysis_list.filter(item => needs.some(ele => ele === item))
				})
				res.fund_list.map(fund => {
					fund.need = fund.needs_analysis_list.filter(item => needs.some(ele => ele === item))
				})
			})
		},
		cellStyle ({ row, column, rowIndex, columnIndex }) {
			if (columnIndex == 0 && row.name === 'Total') {
				return 'text-align: right;padding-right: 30px;'
			}
		},
		// 提交
		productSubmit () {
			this.Dialog.visible = false
			this.$emit('nextBtnRequest')
		},
		getNameByCode (code) {
			return this.needsFor.filter(n => n.code == code)[0] ? this.needsFor.filter(n => n.code == code)[0].name : ''
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		setAllKYC(this.$store, 13)
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	mounted () {
		if (localStorage.getItem('systemPrompts')) {
			setTimeout(() => {
				getSystemPrompts(JSON.parse(localStorage.getItem('systemPrompts')), this.$store, this.$confirm)
			}, 100);
		}
	},
	destroyed () {
	}
}
</script>
<style lang='scss' scoped>
.listStyle {
	background: #eee8e8;
}
.smallBox {
	display: inline-block;
	border-radius: 10px;
	color: #fff;
}
.mlAuto {
	margin-left: auto;
	line-height: 26px;
	font-size: 22px;
	color: #707070;
}
.bigCircle {
	position: absolute;
	right: -102px;
	bottom: -102px;
	background-color: rgba(255, 205, 69, 0.44);
	width: 360px;
	height: 360px;
	border-radius: 360px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.innerCircle {
	background-color: rgba(255, 155, 69, 0.3);
	width: 232px;
	height: 232px;
	border-radius: 232px;
	display: flex;
	align-items: center;
	justify-content: center;
}
/* 产品列表 */
.productItem {
	border: 1px solid #989898;
	background-color: #fff;
	border-radius: 10px;
	padding: 15px;
	margin: 10px 0;
}
.orderStyle {
	line-height: 30px;
	border: 1px solid #8b8b8b;
}
.productNameStyle {
	font-size: 22px;
	color: #3c3c3c;
}
.productDescStyle {
	font-size: 18px;
	color: #707070;
	flex: 1;
}
/* need圆点 */
.circle_small {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin: 10px;
	margin-left: auto;
}
.el-table /deep/ tr td .cell {
	font-size: 16px;
}
</style>